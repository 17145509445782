import React, {useState, useEffect} from 'react';
import "./setup.css"

import disneyplus from '../../images/logo-disney-plus.png'
import mycanal from '../../images/logo-mycanal.jpg'
import ocs from '../../images/logo-ocs.jpg'
import appletvplus from '../../images/logo-appletvplus.jpg'
import netflix from '../../images/logo-netflix.png'
import amazon from '../../images/logo-prime-video.png'
import LayoutMinimal from '../../components/LayoutMinimal';
import { navigate } from 'gatsby';
import { addSubscription, getSubscriptions } from '../../apis/subscriptions';

export default function SetupServices() {
  const [subscriptions, setSubscriptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect( () => {
    async function fetchData() {
      const result = await getSubscriptions()
      setSubscriptions(result.results)
    }
    fetchData()
  },[])

  const onSubmit = () => {
    // navigate("/setup/ratings")
    navigate("/")
  }
  const onSubscribe = serviceName => {
    if (isLoading) return
    setIsLoading(true)
    addSubscription(serviceName)
    let newSubscriptions = subscriptions
    if (typeof newSubscriptions.includes === 'undefined') return
    if (newSubscriptions.includes(serviceName)){
      newSubscriptions = newSubscriptions.filter( i => i !== serviceName)
      setSubscriptions(newSubscriptions)
    } else {
      newSubscriptions.push(serviceName)
      newSubscriptions = newSubscriptions.filter( i => i !== "something not in it")
      setSubscriptions(newSubscriptions)
    }
    setIsLoading(false)
  }

  const subs = typeof subscriptions.includes !== 'undefined' ? subscriptions : []
  
  return (
    <LayoutMinimal>
      <div className="text-center">
        {/* <img className="text-center mx-auto mt-3 rounded" width="80" height="80" src={logo}/> */}
        <div>

          <h1 className="mt-5 font-weight-bold mb-0 pb-0"> Vos services de streaming </h1>
          <p className="mt-0 pt-0 mb-5">
            Sélectionnez les services ausquels vous avez souscrit puis cliquez sur continuer.
            <br/>
            Si vous n'avez aucun service de streaming (svod), cliquez sur <span className="font-italic">aucun</span>.
          </p>

          <div className="d-flex justify-content-center flex-wrap">

            <label className="m-1" htmlFor="Netflix" >                 
              <div className="card" style={{width: '8.5rem'}}>
                  <img loading="lazy" style={{height: '5.6rem'}} src={netflix} className="card-img-top" alt="logo netflix"/>
                  <div className="card-body py-1 text-center">
                      <p className="card-text">
                        Netflix
                        <br/>
                        <input type="checkbox" placeholder="Netflix" name="Netflix" id="Netflix" onChange={ () => onSubscribe("Netflix") } checked={subs.includes("Netflix")}/>
                      </p>
                  </div>
              </div>
            </label>

            <label className="m-1" htmlFor="Amazon Prime Video">
              <div className="card" style={{width: '8.5rem'}}>
                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={amazon} className="card-img-top" alt="logo amazon prime video"/>
                <div className="card-body py-1 text-center">
                    <p className="card-text">
                      Prime Video
                      <br/>
                      <input type="checkbox" placeholder="Amazon Prime Video" name="Amazon Prime Video" id="Amazon Prime Video" onChange={ () => onSubscribe("Amazon Prime Video") } checked={subs.includes("Amazon Prime Video")}/>
                    </p>
                </div>
              </div>
            </label>

            <label className="m-1" htmlFor="Disney Plus">
              <div className="card" style={{width: '8.5rem'}}>
                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={disneyplus} className="card-img-top" alt="logo disney plus"/>
                <div className="card-body py-1 text-center">
                    <p className="card-text">
                      Disney Plus
                      <br/>
                      <input type="checkbox" placeholder="Disney Plus" name="Disney Plus" id="Disney Plus" onChange={ () => onSubscribe("Disney Plus") } checked={subs.includes("Disney Plus")}/>
                    </p>
                </div>
              </div>
            </label>

            <label className="m-1" htmlFor="Apple tv plus" >
              <div className="card" style={{width: '8.5rem'}}>
                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={appletvplus} className="card-img-top" alt="logo Apple tv plus"/>
                <div className="card-body py-1 text-center">
                    <p className="card-text">
                      Apple TV +
                      <br/>
                      <input type="checkbox" placeholder="Apple tv plus" name="Apple tv plus" id="Apple tv plus" checked={subs.includes("Apple TV+")} onChange={ () => onSubscribe("Apple TV+") }/>
                    </p>
                </div>
              </div>
            </label>

            <label className="m-1" htmlFor="My canal" >
              <div className="card" style={{width: '8.5rem'}}>
                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={mycanal} className="card-img-top" alt="logo My canal"/>
                <div className="card-body py-1 text-center">
                    <p className="card-text">
                      My canal
                      <br/>
                      <input type="checkbox" placeholder="My canal" name="My canal" id="My canal" checked={subs.includes("myCANAL")} onChange={ () => onSubscribe("myCANAL") }/>
                    </p>
                </div>
              </div>
            </label>

            <label className="m-1" htmlFor="OCS" >
              <div className="card" style={{width: '8.5rem'}}>
                <img loading="lazy" style={{height: '5.6rem', overflow:'hidden'}} src={ocs} className="card-img-top" alt="logo OCS"/>
                <div className="card-body py-1 text-center">
                    <p className="card-text">
                      OCS
                      <br/>
                      <input type="checkbox" placeholder="OCS" name="OCS" id="OCS" checked={subs.includes("OCS")} onChange={ () => onSubscribe("OCS") }/>
                    </p>
                </div>
              </div>
            </label>

            <label className="m-1" htmlFor="Autre" >
              <div className="card" style={{width: '8.5rem'}}>
                    <div className="d-flex justify-content-center m-auto flex-direction-column" style={{height: '7.7rem',}}>
                      <span className="align-self-center">
                          Autre
                          <br/>
                          <input type="checkbox" placeholder="Autre" name="Autre" id="Autre" onChange={ () => onSubscribe("Autre") } checked={subs.includes("Autre")}/>
                      </span>
                    </div>
              </div>
            </label>


          </div>


          <br />
          <button onClick={onSubmit} className="mt-5 btn btn-md btn-light mr-1"> Aucun</button>
          <button onClick={onSubmit} className="mt-5 btn btn-md btn-warning"> Suivant </button>
        </div>
      </div>
    </LayoutMinimal>
  );
}